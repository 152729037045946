import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import ShopProductList from "../ShopProductList";
import {
  getCookie,
  setCookie,
  deleteCookie,
  transformedData,
} from "../../utils/functions";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "./index.scss";

interface ProductProps {
  products: any;
  productsSettings?: any;
  compareList?: any;
  setCompareList?: any;
  openModal?: any;
  setOpenModal?: any;
}

interface FilterType {
  key: string;
  text: string;
}

const ShopCategory: React.FC<ProductProps> = ({
  products,
  productsSettings,
  compareList,
  setCompareList,
  openModal,
  setOpenModal,
}) => {
  const [openFilter, setOpenFilter] = React.useState(false);
  const [sortigValue, setSortigValue] = React.useState(
    getCookie("sort") || "bestMatch"
  );
  const [productListSave, setProductListSave] = React.useState(products);
  const [productList, setProductList] = React.useState(products);
  const [selectedFilters, setSelectedFilters] = React.useState(
    JSON.parse(getCookie("filter") || "[]") || {}
  );

  React.useEffect(() => {
    handleFilterSelect("", "");
  }, []);

  const handleFilter = (status: boolean) => {
    if (window.innerWidth <= 990) {
      window.scrollTo(0, 0);
      if (status) {
        setOpenFilter(false);
        document.body.classList.remove("no-scroll");
      } else {
        if (openFilter) {
          setOpenFilter(false);
          document.body.classList.remove("no-scroll");
        } else {
          setOpenFilter(true);
          document.body.classList.add("no-scroll");
        }
      }
    }
  };

  const handleFilterSelect = (property: string, value: string) => {
    let newFilter = { ...selectedFilters };
    if (value) {
      newFilter = { ...newFilter, [property]: value };
      handleFilter(false);
    } else {
      delete newFilter[property];
    }
    setSelectedFilters(newFilter);
    setCookie("filter", JSON.stringify(newFilter), 1);
    setProductList(
      productListSave.filter((p: any) => matchesFilter(p, newFilter))
    );
  };

  const handleClearFilter = () => {
    window.scrollTo(0, 0);
    deleteCookie("filter");
    setSelectedFilters({});
    setProductList(productListSave);
  };

  const matchesFilter = (product: any, filter: any) => {
    return Object.keys(filter).every((filterKey) => {
      const filterValue = filter[filterKey];
      const metadata = product?.metadata?.find(
        (mf: any) => mf.key === filterKey
      );
      return metadata && metadata.value === filterValue;
    });
  };

  const getTag2Value = (product: any, property: any) => {
    const tag2 = product?.metadata?.find((mf: any) => mf.key === property);
    return tag2 ? tag2.value : "";
  };

  const getMetafieldValue = (product: any, key: any) => {
    const metadata = product?.metadata?.find((m: any) => m.key === key);
    return metadata ? parseInt(metadata.value, 10) : 0;
  };

  return (
    <>
      <section className="shop-category filter-container">
        <div className="container">
          <div className="shop-category-list">
            <div className="shop-category-header">
              <div className="shop-category-filters">
                <div
                  className="shop-filter-selected"
                  onClick={() => handleFilter(false)}
                >
                  <FilterAltIcon />
                  {productsSettings?.filterName}{" "}
                  {Object.keys(selectedFilters).length > 0 && (
                    <span>{Object.keys(selectedFilters).length}</span>
                  )}
                </div>
                {Object.keys(selectedFilters).length > 0 && (
                  <div
                    className="shop-filter-clear-fitler"
                    onClick={handleClearFilter}
                  >
                    {productsSettings?.clearTitle}
                  </div>
                )}
              </div>
              <div className="list-products-category">
                {productList.length} {productsSettings?.productName}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="shop-category">
        <div className="container">
          {openFilter && (
            <div
              className="filter-bg-cover"
              onClick={() => handleFilter(true)}
            ></div>
          )}
          <div className={openFilter ? "shop-sidebar open" : "shop-sidebar"}>
            <div className="filter-header">
              <CloseIcon onClick={() => handleFilter(true)} />
              <div className="filter-header-count">
                {productList.length} {productsSettings?.productName}
              </div>
              <FilterAltOffIcon onClick={handleClearFilter} />
            </div>
            <div className="shop-category-sidebar">
              {productsSettings?.filterData?.map((g: FilterType, i: any) => {
                return (
                  <div className="shop-filter-widget" key={i}>
                    <h4>{g?.text}</h4>
                    <div className="shop-filter-list-buttons">
                      <ul>
                        {[
                          ...new Set(
                            productList.flatMap((o: any) =>
                              o?.metadata
                                .filter((e: any) => e.key === g?.key)
                                .map((m: any) => m.value)
                            )
                          ),
                        ]
                          .sort((a: any, b: any) =>
                            a.localeCompare(b, undefined, { numeric: true })
                          )
                          .map((e: any, i: any) => {
                            return (
                              <li
                                key={i}
                                onClick={() =>
                                  handleFilterSelect(
                                    g?.key,
                                    selectedFilters[g?.key] === e ? "" : e
                                  )
                                }
                                className={
                                  selectedFilters[g?.key] === e ? "active" : ""
                                }
                              >
                                {e}
                                {selectedFilters[g?.key] === e && <CloseIcon />}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="shop-category-list">
            <div className="shop-category-units">
              {productList?.map((e: any, i: any) => {
                return (
                  <ShopProductList
                    key={i}
                    product={{ ...e, ...transformedData(e.metadata) }}
                    productsSettings={productsSettings}
                    compareList={compareList}
                    setCompareList={setCompareList}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ShopCategory;
